import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Link,
  //    NavLink, useHistory, useLocation
} from "react-router-dom";
//import Swal from "sweetalert2";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
//import Rightcontainer from "../Components/Rightcontainer";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";
import BottonMenu from "./Bottom";
import { yellow } from "@mui/material/colors";

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  // console.log(WebSitesettings);

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          //window.location.href = "/login";
          //window.location.reload()
          //history.push("/login")
        }
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      //window.location.reload()
    }
    role();
    fetchData();
  }, []);
  return (
    <>
      <Header user={userAllData} />

      <div className="leftContainer mb_space">
        <div className="pt-5 mt-5   Orher_page_main_section">
          <div className="main-area">
            {/* <div className="gameCard pt-0">
              <div
                style={{
                  background: "#000",
                  border: "1px solid #585c59",
                  padding: "6px",
                }}
              >
                <h5 className="" style={{ color: "#FFD700" }}>
                  WELCOME to {WebSitesettings.WebsiteName}
                </h5>
                <p style={{ color: "#FFD700" }}>
                  आपसे निवेदन है आप कोई भी बैटल खेलते है। तो क्लासिक मोड में ही
                  खेले तभी आपका गेम स्वीकार होगा। अन्यथा गेम स्वीकार नहीं होगा
                </p>
              </div>
            </div> */}
            <div
              style={{
                background: "#000",
                border: "1px solid #585c59",
                padding: "6px",
                borderRadius: "0.375rem",
                padding: "1rem",
              }}
            >
              <h5 style={{ color: "#FFD700" }}>WELCOME TO LudoBat</h5>
              <h5 style={{ color: "#FFD700" }}>
                {
                  "आपसे निवेदन है आप कोई भी बैटल खेलते है। तो क्लासिक मोड में ही खेले तभी आपका गेम स्वीकार होगा। अन्यथा गेम स्वीकार नहीं होगा"
                }
              </h5>
            </div>
            <div className="success_msg_div" style={{ marginTop: "10px" }}>
              <h5>{WebSitesettings.msg}</h5>
            </div>
            <section className="games-section p-3">
              <div className="d-flex align-items-center games-section-title">
                Our Tournaments
              </div>
              <div className="games-section-headline mt-2 mb-1">
                <div className="games-window ">
                  <Link
                    className="gameCard-container"
                    to={`/Homepage/Ludo%20Classics`}
                  >
                    <picture className="gameCard-image">
                      <img
                        width="100%"
                        src={
                          WebSitesettings.isLandingImage1 === false ||
                          WebSitesettings.LandingImage1 == null
                            ? process.env.PUBLIC_URL +
                              "/Images/LandingPage_img/LudoClassic.jpg"
                            : baseUrl + WebSitesettings.LandingImage1
                        }
                        alt=""
                      />
                    </picture>
                    <span className="d-none blink text-success d-block text-right">
                      ◉ LIVE
                    </span>
                    {/* <div className="gameCard-title">
                    <span className="d-none blink text-success d-block text-right">
                      ◉ LUDO CLASSICS
                    </span>
                  </div> */}
                    {/* <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div> */}
                  </Link>

                  {/* <Link className="gameCard-container" to={`/Homepage/Ludo%20Popular`}> */}
                  <Link
                    className="gameCard-container"
                    to={`/Homepage/Ludo%20Popular`}
                  >
                    <picture className="gameCard-image">
                      <img
                        width="100%"
                        src={
                          WebSitesettings.isLandingImage2 === false ||
                          WebSitesettings.LandingImage2 == null
                            ? process.env.PUBLIC_URL +
                              "/Images/LandingPage_img/LudoTeam.jpg"
                            : baseUrl + WebSitesettings.LandingImage2
                        }
                        alt=""
                      />
                    </picture>
                    <span className="d-none blink text-success d-block text-right">
                      ◉ LIVE
                    </span>
                    {/* <div className="gameCard-title">
                    <span className="d-none blink text-success  d-block text-right">
                      ◉ TEAM LUDO
                    </span>
                  </div> */}
                    {/* <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div> */}
                  </Link>
                  <Link className="gameCard-container" to={``}>
                    <picture className="gameCard-image">
                      <img
                        width="100%"
                        src={
                          WebSitesettings.isLandingImage3 === false ||
                          WebSitesettings.LandingImage3 == null
                            ? process.env.PUBLIC_URL +
                              "/Images/LandingPage_img/LudoSnake.jpg"
                            : baseUrl + WebSitesettings.LandingImage3
                        }
                        alt=""
                      />
                    </picture>
                    <span className="d-none blink text-success d-block text-right">
                      ◉ COOMING SOON
                    </span>
                    {/* <div className="gameCard-title">
                      <span className="d-none blink text-success  d-block text-right">
                        ◉ LUDO SNAKE
                      </span>
                    </div> */}
                    <div className="goverlay">
                      <div className="text">Comming Soon</div>
                    </div>
                  </Link>
                  <Link className="gameCard-container" to={``}>
                    <picture className="gameCard-image">
                      <img
                        width="100%"
                        src={
                          WebSitesettings.isLandingImage4 === false ||
                          WebSitesettings.LandingImage4 == null
                            ? process.env.PUBLIC_URL +
                              "/Images/LandingPage_img/FirstSixWin.jpg"
                            : baseUrl + WebSitesettings.LandingImage4
                        }
                        alt=""
                      />
                    </picture>
                    <span className="d-none blink text-success d-block text-right">
                      ◉ COMMING SOON
                    </span>
                    {/* <div className="gameCard-title">
                      <span className="d-none blink text-success  d-block text-right">
                        ◉ FIRST SIX WIN
                      </span>
                    </div> */}
                    <div className="goverlay">
                      <div className="text">Comming Soon</div>
                    </div>
                  </Link>
                </div>
              </div>
            </section>
            <div className="downloadButton mt-3">
              <Downloadbutton />
            </div>
          </div>
        </div>
      </div>
      {/* // <div className='rightContainer'>
            //     <Rightcontainer/>
            // </div> */}
    </>
  );
}
