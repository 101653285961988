import { NavLink } from "react-router-dom";
import { SlHome } from "react-icons/sl";
import { BsWallet, BsGift } from "react-icons/bs";
import { FiUser, FiUsers } from "react-icons/fi";
import { AiOutlineMessage } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";

const BottonMenu = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="main_bottom_menus" style={{}}>
      <nav className="bottom_link_main">
        <NavLink
          exact
          to="/landing"
          className="bottom_link "
          activeClassName="active_link"
        >
          <SlHome className="sidebar_link_icon" />
          <span> Home</span>
        </NavLink>
        <NavLink
          exact
          to="/wallet"
          className="bottom_link "
          activeClassName="active_link"
        >
          <BsWallet className="sidebar_link_icon" />

          <span> My wallet</span>
        </NavLink>
        <NavLink
          exact
          to="/refer"
          className="bottom_link "
          activeClassName="active_link"
        >
          <FiUsers className="sidebar_link_icon" />

          <span> Refer</span>
        </NavLink>
        <a
          exact
          href={"https://wa.me/" + WebSitesettings.CompanyMobile}
          className="bottom_link "
          activeClassName="active_link"
          target="_blank"
        >
          <FaWhatsapp className="sidebar_link_icon" />

          {/* <img
            width="25px"
            src="/whatsapp.png"
            alt="WhatsApp Logo"
            className="sidebar_link_icon"
          /> */}
          {/* <BiMessageSquareDetail /> */}

          <span> Support</span>
        </a>
        <NavLink
          exact
          to="/Profile"
          className="bottom_link "
          activeClassName="active_link"
        >
          <FiUser className="sidebar_link_icon" />

          <span> Profile</span>
        </NavLink>
      </nav>
    </div>
  );
};

export default BottonMenu;
