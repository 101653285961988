import React, { useEffect, useState } from "react";
import "../css/layout.css";
import css from "../css/Profile.module.css";
import { Link, useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import axios from "axios";
import { IoCall } from "react-icons/io5";
import Rightcontainer from "../Components/Rightcontainer";
import Swal from "sweetalert2";
import Header from "../Components/Header";

const Profile1 = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [show, setShow] = useState(false);

  const [referral, setCode] = useState("");
  const [Id, setId] = useState(null);
  const [profile, setProfile] = useState();
  const [portcss, setPortcss] = useState(css.active_tab);
  const [portcss1, setPortcss1] = useState(css.inactive_tab);
  const [crushcss, setCrushcss] = useState(true);
  const [holder_name, setHolder_name] = useState();
  const [account_number, setAccount_number] = useState();
  const [ifsc_code, setIfsc_code] = useState();
  const [upi_id, setUpi_id] = useState();
  const history = useHistory();
  const logout = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        // setUser(res.data)
        localStorage.removeItem("token");
        //window.location.reload();
        history.push("/login");
        window.location.reload(true);
      })
      .catch((e) => {
        // alert(e.msg)
      });
  };
  const UserALL = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setProfile(res.data);
        setId(res.data._id);
        TotalGame(res.data._id);
        setName(res.data.Name);
        setCode(res.data.referral);
        setHolder_name(res.data.holder_name);
        setAccount_number(res.data.account_number);
        setIfsc_code(res.data.ifsc_code);
        setUpi_id(res.data.upi_id);
      })
      .catch((e) => {
        // alert(e.msg)
      });
  };
  const [Name, setName] = useState();
  const UpdateProfile = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          Name,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data == "User name already exist!") {
          Swal.fire({
            title: res.data,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          setName(res.data);
          UserALL();
        }
      });
  };
  const updateBankDetails = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          holder_name,
          account_number,
          ifsc_code,
          upi_id,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          setShow((prev) => !prev);
          UserALL();
          let message;
          message = res.data.msg;
          if (!res.data.msg) {
            message = "something went wrong";
          }
          Swal.fire({
            title: message,
            icon: res.data.submit ? "success" : "error",
            confirmButtonText: "OK",
          });
        }
      });
  };
  const update_RefCode = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          referral,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          UserALL();
          let message;
          message = res.data.msg;
          if (!res.data.msg) {
            message = "Invalid referral Code";
          }
          Swal.fire({
            title: message,
            icon: res.data.submit ? "success" : "error",
            confirmButtonText: "OK",
          });
        }
      });
  };
  //// total game
  const [total, setTotal] = useState();
  const TotalGame = (Id) => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `total/user/all/${Id}`, { headers })
      .then((res) => {
        setTotal(res.data);
      })
      .catch((e) => {});
  };

  const [pic, setPic] = useState();
  const Result = async (file) => {
    if (file) {
      const access_token = localStorage.getItem("token");
      // // console.log(access_token)
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      const formData = new FormData();
      formData.append("avatar", file);

      // console.log(formData)
      await fetch(baseUrl + `users/me/avatar`, {
        method: "POST",
        body: formData,
        headers,
      }).then((res) => {
        UserALL();
      });
    }

    // await axios.patch(baseUrl+`challange/result/${path}`,
    //   {
    //     status: status
    //   },
    //   { headers })
    //   .then((res) => {
    //     // getPost(res.data)
    //     // console.log(res.data);
    //     history.push("/landing")
    // }).catch((e) => {
    //     // console.log(e);
    //   })
  };
  //avatar

  useEffect(() => {
    UserALL();
  }, []);

  return (
    <>
      <Header user={profile} />
      <div
        className="leftContainer mb_space"
        style={{
          minHeight: "100vh",
          paddingTop: "80px",
          paddingBottom: "60px",
        }}
      >
        <div className="profile_page">
          <div className=" mt-4 profile_div_main_box">
            <div>
              <label>
                <input
                  className="d-none"
                  type="file"
                  onChange={(e) => Result(e.target.files[0])}
                  accept="image/*"
                  required
                />
                <picture>
                  {profile && profile.avatar ? (
                    <img
                      height="80px"
                      width="80px"
                      src={baseUrl + `${profile && profile.avatar}`}
                      alt=""
                      style={{ borderRadius: "50px" }}
                    />
                  ) : (
                    <img
                      height="80px"
                      width="80px"
                      src={process.env.PUBLIC_URL + `/user.png`}
                      alt=""
                      style={{ borderRadius: "50px" }}
                    />
                  )}
                </picture>
              </label>
            </div>

            <div className="profile_details ">
              <h2>
                {profile && profile.Name}
                <img
                  className={`ml-2`}
                  width="20px"
                  src={"Images/icon-edit.jpg"}
                  alt="Edit"
                  onClick={() => {
                    setPortcss(css.inactive_tab);
                    setPortcss1(css.active_tab);
                  }}
                />
              </h2>
              <div className={`text-bold my-3 ${portcss1}`}>
                <div
                  className={`${css.MuiFormControl_root} ${css.MuiTextField_root}`}
                  style={{ verticalAlign: "bottom" }}
                >
                  <div
                    className={`${css.MuiInputBase_root} ${css.MuiInput_root} ${css.MuiInput_underline} ${css.MuiInputBase_formControl} ${css.MuiInput_formControl}`}
                  >
                    <input
                      aria-invalid="false"
                      type="text"
                      maxLength={"20"}
                      className={`${css.MuiInputBase_input} ${css.MuiInput_input}`}
                      placeholder="Enter Username"
                      value={Name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <img
                  className="ml-2"
                  width="20px"
                  src={"/Images/select-blue-checkIcon.png"}
                  alt=""
                  onClick={() => {
                    setPortcss(css.active_tab);
                    setPortcss1(css.inactive_tab);
                    UpdateProfile(Id);
                  }}
                />
              </div>
              <h3>
                <IoCall className="card_icons" /> {profile && profile?.Phone}
              </h3>
            </div>
          </div>
        </div>
        <div className="Complete_Profile_box">
          <div className="new_kyc">
            <Link
              className={`d-flex align-items-center  complete_kyc_btn`}
              to={
                profile && profile.verified === `unverified`
                  ? `/Kyc2`
                  : `/Profile`
              }
            >
              <picture className="ml-2">
                <img
                  width="20px"
                  src={process.env.PUBLIC_URL + "/Images/kyc-icon-new.png"}
                  alt=""
                  className=""
                />
              </picture>
              {profile && profile.verified === `unverified`
                ? "Complete KYC"
                : profile && profile.verified === "pending"
                ? "In Process"
                : profile && profile.verified === "verified"
                ? "Completed Kyc ✅"
                : "Completed Kyc ✅"}
            </Link>
            <Link
              to="/Addcase"
              className={`d-flex align-items-center  complete_kyc_btn`}
            >
              <picture className="ml-2">
                <img
                  width="20px"
                  src={process.env.PUBLIC_URL + "/Images/sidebar-wallet.png"}
                  alt=""
                />
              </picture>
              <h2 className={` ${css.mytext}`}>Wallet</h2>
            </Link>
          </div>
        </div>
        <div className="profile_info_small_box_main_row">
          <h2 className="profile_headings">Other Details</h2>

          <div className="row rows">
            <div className="col-4 col-lg-4 col-md-4 col-sm-6">
              <div className="profile_info_small_box_main ">
                <div className="profile_info_small_box_inner bg_1">
                  <span>₹</span>
                </div>
                <h3>Coin Won</h3>
                <h4> {profile && profile.wonAmount}</h4>
              </div>
            </div>
            <div className="col-4 col-lg-4 col-md-4 col-sm-6">
              <div className="profile_info_small_box_main ">
                <div className="profile_info_small_box_inner bg_3">
                  <img src="/images/battle.png" alt="" className="img-fluid" />
                </div>
                <h3>Battle Played</h3>
                <h4> {total && total}</h4>
              </div>
            </div>

            <div className="col-4 col-lg-4 col-md-4 col-sm-6">
              <div className="profile_info_small_box_main ">
                <div className="profile_info_small_box_inner bg_2">
                  <img src="/images/earning.png" alt="" className="img-fluid" />
                </div>
                <h3>Referral</h3>

                <h4> {profile && profile.referral_earning}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="logout_div">
          <Link
            to="/login"
            className={`${css.center_xy} `}
            onClick={(e) => logout(e)}
          >
            Log Out
          </Link>
        </div>
      </div>
    </>
  );
};
export default Profile1;
